import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";


export default function ScrollDialog(props) {

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        props.onClose()
    };

    const handleSave = () => {
        props.onSave()
        props.onClose()
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{props.title}</DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        component="div"
                        sx={{
                            '& .MuiTextField-root': {m: 1, width: '25ch'},
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        {props.children}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Mégse</Button>
                    <Button variant="outlined" color="success" onClick={handleSave}>Mentés</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}