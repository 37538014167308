import React, {useEffect, useRef, useState, useContext} from "react";
import {getFormData} from "../../utils/FormUtils";
import MyDrawer from "../../components/Drawer";
import MainCarousel from "../../components/MainCarousel";
import Calendar from "../../components/Calendar";
import hu from "date-fns/locale/hu";
import CenteredTabs from "../../components/Tabs";
import Footer from "../../components/Footer";
import useCallDataApi from "../../hooks/data";
import {format} from "date-fns";
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import HomePageCard from "../../components/HomePageCard";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import ConfigContext from "../../context/ConfigContext";
import AttractionCard from "../../components/AttractionCard";


export default function HomePage() {
    const {getUnauthData, postUnauthData} = useCallDataApi("reservations");
    const {getUnauthData: getEvents} = useCallDataApi("events");
    const {getUnauthData: getAllRooms} = useCallDataApi("rooms");
    const {getUnauthData: getApartment} = useCallDataApi("apartments");
    const {getUnauthData: getAttractions} = useCallDataApi("attractions");
    const [reservations, setReservations] = useState([]);
    const [events, setEvents] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [room, setRoom] = useState(1);
    const [guests, setGuests] = useState(1);
    const [attractions, setAttractions] = useState([]);
    const [apartment, setApartment] = useState({});
    const [reservationText, setReservationText] = useState({});
    const [maxDate, setMaxDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const personForm = useRef(null)
    const companyForm = useRef(null)
    const datesForm = useRef(null)
    const {baseUrl, apartmentId} = useContext(ConfigContext);


    const getEventsForApartment = () => {
        getEvents(`get_for_apartment?apartment=${apartmentId}`)
            .then((e) => {
                setEvents(e);
            });
    }

    const getRooms = () => {
        getAllRooms(`get_for_apartment?apartment=${apartmentId}`).then(r => {
            setRooms(r)
        })
    }

    const getAllAttractions = () => {
        getAttractions(`get_for_apartment?apartment=${apartmentId}`).then(r => {
            setAttractions(r)
        })
    }

    const getReservations = () => {
        getUnauthData(`all?room=${room}`).then((r) => {
            setReservations(r)
        })
    }

    const getApartmentInfo = () => {
        getApartment(`${apartmentId}/get`).then((r) => setApartment(r))
    }

    const getReservationText = () => {
        getUnauthData(`reservation_text?apartment=${apartmentId}`).then((r) => {
            setReservationText(r)
        })
    }

    const getMaxDate = () => {
        getUnauthData(`until_date?a=${apartmentId}`)
            .then(r => setMaxDate(r.date))
    }

    const getRoomCapacity = (id) => {
        return rooms.find(r => r.id == id)?.capacity
    }

    useEffect(() => {
        getReservations();
        getEventsForApartment();
        getMaxDate()
        getRooms()
        getReservationText()
        getApartmentInfo()
        getAllAttractions()
    }, [room])

    const PaddingContainer = ({children}) => {
        return (
            <div className="mainPadding">
                {children}
            </div>
        )
    }

    const reserve = (reserveType) => {
        let form;
        if (reserveType === 'person') form = getFormData(personForm.current)
        else form = getFormData(companyForm.current)
        const dates = getFormData(datesForm.current)
        postUnauthData('payment/', {...form, ...dates, room: room, guests: guests})
            .then(r => {
                if (r !== null) window.location.href = r
            })
    }

    const handleChange = (event) => {
        setRoom(event.target.value);
    };

    const handleGuestChange = (event) => {
        setGuests(event.target.value);
    };

    function createMarkup() {
        return {__html: reservationText?.text};
    }


    return <MyDrawer title={apartment?.name}>
        <>
            <MainCarousel apartment={1}/>
            <PaddingContainer>
                <Typography variant="secondary2" gutterBottom dangerouslySetInnerHTML={createMarkup()}/>
                {events && <Typography variant="h5" gutterBottom>Események:</Typography>}
                <Grid container spacing={2} style={{marginBottom: '20px'}}>
                    {events.map((e, i) => <Grid key={`event_${i}`} item xs={12} lg={6}>
                        <HomePageCard title={e.name}
                                      image={baseUrl.substring(0, baseUrl.length - 1) + e.image}
                                      description={e.description}
                                      startDate={e.start_date}
                                      endDate={e.end_date}
                                      percent={e.percent}/>
                    </Grid>)}
                </Grid>
                {attractions && <Typography variant="h5" gutterBottom>Látványosságok:</Typography>}
                <Grid container spacing={2} style={{marginBottom: '20px'}}>
                    {attractions.map((e, i) => <Grid key={`attraction_${i}`} item xs={12} lg={6}>
                        <AttractionCard name={e.name}
                                        image={baseUrl.substring(0, baseUrl.length - 1) + e.image}
                                        description={e.description}/>
                    </Grid>)}
                </Grid>
                <Grid container spacing={2} style={{marginBottom: '20px'}}>
                    <Grid item xs={12} xl={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Válasszon apartmant:</Typography>
                                <Box sx={{minWidth: 120}}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="apartment" name="apartment" value={room} onChange={handleChange}>
                                            {rooms.map((r, i) => (
                                                <FormControlLabel key={`room_${i}`} value={r.id} control={<Radio />} label={r.name} />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
{/*                                     <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label1">Apartman</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label1"
                                            id="demo-simple-select1"
                                            value={room}
                                            label="Szoba"
                                            onChange={handleChange}
                                        >
                                            {rooms.map((r, i) => <MenuItem key={`room_${i}`}
                                                                           value={r.id}>{r.name}</MenuItem>)}
                                        </Select>
                                    </FormControl> */}
                                </Box>

                                <Typography variant="h5">Vendégek száma:</Typography>
                                <Box sx={{minWidth: 120}}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label2">Vendégek száma</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label2"
                                            id="demo-simple-select2"
                                            value={guests}
                                            label="Vendégek száma"
                                            onChange={handleGuestChange}
                                        >
                                            {Array.apply(null, {length: getRoomCapacity(room)})
                                                .map((e, i) =>
                                                    <MenuItem key={`guest_${i}`} value={i + 1}>{i + 1}</MenuItem>
                                                )}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Calendar name="Naptár"
                          minDate={format(new Date(), 'yyyy-MM-dd')}
                          maxDate={maxDate}
                          reservedDateRanges={reservations}
                          extraDateRanges={events}
                          locale={hu} data={datesForm}/>
                <CenteredTabs personData={personForm} companyData={companyForm}
                              handleReserve={reserve}/>
            </PaddingContainer>

            <Footer/>


            {/*<AlertDialog*/}
            {/*    onClose={() => (setEventDialogOpen(false))}*/}
            {/*    open={isEventDialogOpen}*/}
            {/*    title={getEvent(current)?.name}*/}
            {/*    content={getEvent(current)?.description}*/}
            {/*    image={getEvent(current)?.image}*/}
            {/*    extra={`Kezdete: ${monthNameFormat(getEvent(current)?.start_date)} Vége: ${monthNameFormat(getEvent(current)?.start_date)} | Ár: ${thousandSeparator(getEvent(current)?.price)} HUF / éj`}*/}
            {/*/>*/}

        </>
    </MyDrawer>;
}