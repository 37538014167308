import {Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField} from "@mui/material";
import LocationInput from "./LocationInput";


export default function PersonData({reference, handle}) {
    return (<form ref={reference}>
        <Grid container spacing={2} >
            <Grid item xs={6}>
                <TextField name="first_name" style={{'width': '100%'}} label="Keresztnév"/>
            </Grid>
            <Grid item xs={6}>
                <TextField name="last_name" style={{'width': '100%'}} label="Vezetékév"/>
            </Grid>
            <LocationInput/>
            <Grid item xs={12}>
                <TextField name="email" style={{'width': '100%'}} label="Email"/>
            </Grid>
            <Grid item xs={12}>
                <TextField name="phone" style={{'width': '100%'}} label="Telefonszám"/>
            </Grid>
            <Grid item xs={12}>
                <TextField name="coupon-code" style={{'width': '100%'}} label="Kupon kód"/>
            </Grid>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel control={<Checkbox name='agree_field' />} label="Elfogadom az adatkezelési szabályzatban foglaltakat" />
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" style={{float: 'right'}} onClick={() => handle('person')}>Foglalás</Button>
            </Grid>
        </Grid>


    </form>)
}