import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {format, parseISO} from "date-fns";
import hu from "date-fns/locale/hu";
import {percentFormat} from "../utils/utils";

export default function HomePageCard({image, title, description, startDate, endDate, percent, onClick}) {

    const monthNameFormat = (date) => {
        return format(parseISO(date), 'yyyy LLLL dd', { locale: hu })
    }

    return (
        <Card sx={{ width: '100%' }} onClick={onClick}>
            <CardMedia
                component="img"
                height="300"
                image={image}
                alt={title}
            />
            <CardContent>
                <Typography variant="h5" component="div">
                    {title}
                </Typography>
                <Typography gutterBottom variant="body2" color="text.secondary">
                    {monthNameFormat(startDate)} - {monthNameFormat(endDate)}
                </Typography>
                <hr/>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}
