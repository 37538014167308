import {createContext, useState, useEffect, useCallback, useContext} from "react";
import jwt_decode from "jwt-decode";
import {useNavigate} from "react-router-dom";
import ConfigContext from "./ConfigContext";


const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {
    const {baseUrl} = useContext(ConfigContext)
    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens") ? JSON.parse(localStorage.getItem("authTokens")) : null
    );
    const [user, setUser] = useState(() =>
        localStorage.getItem("authTokens") ? jwt_decode(localStorage.getItem("authTokens")) : null
    );
    const [permissions, setPermissions] = useState(() =>
        localStorage.getItem("permissions") ? localStorage.getItem("permissions") : [])
    const [username, setUsername] = useState(() =>
        localStorage.getItem("username") ? localStorage.getItem("username") : "")
    const [loading, setLoading] = useState(true);

    const history = useNavigate();

    const loginUser = async (e) => {
        e.preventDefault();
        const response = await fetch(`${baseUrl}api/token/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({username: e.target.username.value, password: e.target.password.value}),
        });
        const data = await response.json();

        if (response.status === 200) {
            setAuthTokens(data);
            setUser(jwt_decode(data.access));
            const perms = jwt_decode(data.access).permissions;
            const uname = jwt_decode(data.access).username;
            setPermissions(perms);
            setUsername(uname);
            localStorage.setItem("authTokens", JSON.stringify(data));
            localStorage.setItem("permissions", perms);
            localStorage.setItem("username", uname);
            history("/reservations");
        } else {
            alert("Something went wrong!");
        }
    };

    const registerUser = async (e) => {
        e.preventDefault();
        const response = await fetch(`${baseUrl}core/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({email: e.target.email.value, username: e.target.username.value, password: e.target.password.value}),
        });
        if (response.status === 201) {
            history("/login");
        } else {
            alert("Something went wrong!");
        }
    };

    const logoutUser = useCallback(() => {
        setAuthTokens(null);
        setUser(null);
        setPermissions(null);
        localStorage.removeItem("authTokens");
        localStorage.removeItem("permissions");
        localStorage.removeItem("username");
        history("/login");
    }, [history]);

    const updateToken = useCallback(async () => {
        const response = await fetch(`${baseUrl}api/token/refresh/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({refresh: authTokens?.refresh}),
        });

        const data = await response.json();

        if (response.status === 200) {
            setAuthTokens(data);
            setUser(jwt_decode(data.access));
            const perms = jwt_decode(data.access).permissions;
            const uname = jwt_decode(data.access).username;
            setPermissions(perms);
            setUsername(uname);
            localStorage.setItem("authTokens", JSON.stringify(data));
            localStorage.setItem("permissions", perms);
            localStorage.setItem("username", uname);
        } else if (response.status === 400) {
            console.log(response.status)

        } else {
            logoutUser();
        }

        if (loading) {
            setLoading(false);
        }
    }, [authTokens?.refresh, loading, logoutUser]);

    const contextData = {
        user: user,
        authTokens: authTokens,
        loginUser: loginUser,
        registerUser: registerUser,
        logoutUser: logoutUser,
        permissions: permissions,
        username: username
    };

    useEffect(() => {
        if (loading) {
            updateToken();
        }


        const fourMinutes = 1000 * 60 * 4;

        const interval = setInterval(() => {
            if (authTokens) {
                updateToken()
            }
        }, fourMinutes);
        return () => clearInterval(interval);
    }, [authTokens, loading, updateToken]);

    return <AuthContext.Provider value={contextData}>{loading ? null : children}</AuthContext.Provider>;
};