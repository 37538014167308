import './App.css';
import {createTheme, ThemeProvider} from "@mui/material";
import {huHU} from "@mui/material/locale";
import HomePage from "./pages/Customer/HomePage";
import Success from "./pages/Customer/Success";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import LoginPage from "./pages/Admin/Login";
import {AuthProvider} from "./context/AuthContext";
import {ConfigProvider} from "./context/ConfigContext";
import PrivateRoute from "./utils/PrivateRoute";
import CouponCodes from "./pages/Admin/CouponCodes/CouponCodes";
import Rooms from "./pages/Admin/Rooms/Rooms";
import AdminSettings from "./pages/Admin/Settings/AdminSettings";
import Reservations from './pages/Admin/Reservations/Reservations';
import GalleryImages from "./pages/Admin/GalleryImages/GalleryImages";
import CouponCodeActivated from "./pages/Customer/CouponCodeActivated";
import MainText from "./pages/Admin/MainText/MainText";
import EmailTexts from "./pages/Admin/EmailTexts/EmailTexts";
import Events from "./pages/Admin/Events/Events";
import Attractions from "./pages/Admin/Attractions/Attractions";
// import useCallDataApi from "./hooks/data";
// import {useEffect} from "react";



function App() {
    // const {getUnauthData, postData} = useCallDataApi("apartments");
    const theme = createTheme({
        palette: {
            primary: {
                main: '#000',
            },
            secondary: {
                main: '#fff',
            },
        },
        typography: {
            fontFamily: 'Noto Serif Gujarati',
        }
    }, huHU)


    // const getApartments = () => {
    //     getUnauthData().then(r => console.log(r))
    // }
    //
    // useEffect(getApartments, [])


    return (
        <div className="App">
            <Router>
                <ConfigProvider>
                    <AuthProvider>
                        <ThemeProvider theme={theme}>
                            <Routes>
                                <Route element={<HomePage/>} path="/"/>
                                <Route element={<Success/>} path="/success"/>
                                <Route element={<CouponCodeActivated/>} path="/activated-coupon-code"/>
                                <Route element={<LoginPage/>} path="/login"/>

                                <Route path='/reservations' element={<PrivateRoute/>}>
                                    <Route element={<Reservations/>} path="/reservations"/>
                                </Route>
                                <Route path='/events' element={<PrivateRoute/>}>
                                    <Route element={<Events/>} path="/events"/>
                                </Route>
                                <Route path='/coupon-codes' element={<PrivateRoute/>}>
                                    <Route element={<CouponCodes/>} path="/coupon-codes"/>
                                </Route>
                                <Route path='/rooms' element={<PrivateRoute/>}>
                                    <Route element={<Rooms/>} path="/rooms"/>
                                </Route>
                                <Route path='/settings' element={<PrivateRoute/>}>
                                    <Route element={<AdminSettings/>} path="/settings"/>
                                </Route>
                                <Route path='/gallery-images' element={<PrivateRoute/>}>
                                    <Route element={<GalleryImages/>} path="/gallery-images"/>
                                </Route>
                                <Route path='/main-text' element={<PrivateRoute/>}>
                                    <Route element={<MainText/>} path="/main-text"/>
                                </Route>
                                <Route path='/email-texts' element={<PrivateRoute/>}>
                                    <Route element={<EmailTexts/>} path="/email-texts"/>
                                </Route>
                                <Route path='/attractions' element={<PrivateRoute/>}>
                                    <Route element={<Attractions/>} path="/attractions"/>
                                </Route>
                            </Routes>
                        </ThemeProvider>
                    </AuthProvider>
                </ConfigProvider>
            </Router>
        </div>
    );
}

export default App;
