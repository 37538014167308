import {TextField} from "@mui/material";
import React, {useState} from "react";

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import adapterLocale from 'date-fns/locale/en-GB'
import {DatePicker} from "@mui/x-date-pickers";


const RoomData = ({reference, obj}) => {
    return <>
        <form ref={reference}>
            <TextField id="name" name="name" label="Név" defaultValue={obj?.name || ''}/>
            <TextField id="capacity" name="capacity" label="Férőhelyek száma" type="number" defaultValue={obj?.capacity || ''}/>
        </form>
    </>;
}

export default RoomData