import CardView from "../../../components/Views/CardView";
import {Button, Card, CardActions, CardContent, CardMedia, Typography} from "@mui/material";
import {useRef} from "react";
import EventData from "./EventData";
import {monthNameFormat, percentFormat} from "../../../utils/utils";


export const PropCard = ({obj, editHandler = null, deleteHandler = null}) => <Card sx={{ width: '100%' }}>
    <CardMedia
        component="img"
        height="300"
        image={obj.image}
        alt={obj.title}
    />
    <CardContent>
        <Typography variant="h5" component="div">
            {obj.title}
        </Typography>
        <Typography gutterBottom variant="body2" color="text.secondary">
            {monthNameFormat(obj.start_date)} - {monthNameFormat(obj.end_date)} | {obj.percent !== 0 ? percentFormat(obj.percent) : 'Nem módosít árat'}
        </Typography>
        <hr/>
        <Typography variant="body2" color="text.secondary">
            {obj.description}
        </Typography>
    </CardContent>
    <CardActions>
        <Button size="small" variant="outlined" color="success" onClick={editHandler}>Szerkesztés</Button>
        <Button size="small" variant="outlined" color="error" onClick={deleteHandler}>Törlés</Button>
    </CardActions>
</Card>


const NewEvents = () => {
    const eventForm = useRef(null)
    return <CardView
        title='esemény'
        imageFiledName="image"
        pluralTitle='Események'
        baseSlug='events'
        PropCard={PropCard}
        formRef={eventForm}
        form={<EventData reference={eventForm}/>}
    />
}

export default NewEvents