import {Grid, TextField} from "@mui/material";
import {ChangeEvent, useState} from "react";
import useCallDataApi from "../hooks/data";
import CountrySelect from "./CountrySelect";

const LocationInput = () => {
    const [country, setCountry] = useState('')
    const [zipCode, setZipCode] = useState('');
    const [inputState, setInputState] = useState('');
    const [city, setCity] = useState('');
    const {getDataByZipCode} = useCallDataApi();

    const handleZipCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setZipCode(event.target.value);
    }

    const handleCityChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
    }

    const handleInputStateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputState(event.target.value);
    }

    const handleCountryChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCountry(event.target.value);
    }

    const checkZipCode = async () => {
        if(zipCode.length === 4) {
            const location = await getDataByZipCode(!country ? 'hu' : country.toLowerCase(),zipCode);
            location ? setInputState(location.places[0].state) : setInputState('');
            location ? setCity(location?.places[0]['place name']) : setCity('');
        }

    }

    return (
        <>
            <Grid item xs={6} md={3}>
                <CountrySelect onChange={handleCountryChange} value={country} />
            </Grid>
            <Grid item xs={6} md={2}>
                <TextField name="zip" style={{'width': '100%'}} label="Irányítószám" onBlur={checkZipCode} value={zipCode} onChange={handleZipCodeChange} type="number" />
            </Grid>
            <Grid item xs={6} md={4}>
                <TextField name="state" style={{'width': '100%'}} label="Megye" value={inputState} onChange={handleInputStateChange} />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField name="city" style={{'width': '100%'}} label="Város" value={city} onChange={handleCityChange}/>
            </Grid>
            <Grid item xs={12} md={12}>
                <TextField name="street" style={{'width': '100%'}} label="Utca, házszám" type="text"/>
            </Grid>
        </>
);
}
export default LocationInput;