import {format, parseISO} from "date-fns";
import hu from "date-fns/locale/hu";

const thousandSeparator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const monthNameFormat = (date) => {
    if (date) return format(parseISO(date), 'yyyy LLLL dd', { locale: hu })
    else return ''

}

const percentFormat = (num) => {
    return `${num * 100} %`
}

export {thousandSeparator, monthNameFormat, percentFormat}