import MiniDrawer from "../AdminDrawer";
import DataTable from "../DataTable";
import {GridActionsCellItem} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import BasicSpeedDial from "../SpeedDial";
import AddIcon from '@mui/icons-material/Add';
import ScrollDialog from "../ScrollDialog";
import {getFormData} from "../../utils/FormUtils";
import {useSnackbar} from "notistack";
import AlertDialog from "../AlertDialog";


const ListView = ({
                      baseSlug,
                      getSlug = '',
                      title,
                      pluralTitle,
                      formRef,
                      form,
                      propColumns = [],
                      extraColActions = [],
                      extraAlertDialogs = [],
                      extraSpeedDialActions = [],
                      extraScrollDialogs = []
                  }) => {
    const [current, setCurrent] = useState(1)
    const [data, setData] = useState([])
    const [newPopupOpen, setNewPopupOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [editPopupOpen, setEditPopupOpen] = useState(false)
    const [deletePopupOpen, setDeletePopupOpen] = useState(false)
    const {getData, createData, updateData, deleteData} = useCallDataApi(baseSlug);
    const {enqueueSnackbar} = useSnackbar();


    const columns = [
        {
            field: "manage",
            headerName: "Manage",
            sortable: false,
            type: "actions",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        setDeletePopupOpen(true)
                    }}
                    label="Törlés"
                    showInMenu={true}
                />,
                <GridActionsCellItem
                    icon={<EditIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        setEditPopupOpen(true)
                    }}
                    label="Szerkesztés"
                    showInMenu={true}
                />,
                ...extraColActions.filter(action => action.show(params)).map((action, i) =>
                    <GridActionsCellItem
                        key={`action_${i}`}
                        icon={action.icon}
                        onClick={() => {
                            action.onClick(params.row['id'])
                        }}
                        label={action.label}
                        showInMenu={action.showInMenu}
                    />)
            ],
        },
        ...propColumns
    ];

    const speedDialActions = [{
        icon: <AddIcon onClick={() => setNewPopupOpen(true)}/>,
        name: `Új ${title}`,
    }, ...extraSpeedDialActions];

    const generalGetList = () => {
        getData(getSlug).then(r => {
            setData(r)
            setLoading(false)
        })
    }

    const generalNew = (o) => {
        createData(o).then(newObj => {
            if (newObj) {
                setData(r => [...data, newObj])
                enqueueSnackbar('Elmentve!', {variant: 'success'})
            }
        })
    }

    const generalEdit = (o) => {
        updateData(current, o).then(editedObj => {
            if (editedObj) {
                const restOfData = data.filter(r => r.id !== current)
                setData([...restOfData, editedObj])
                enqueueSnackbar('Szerkesztve!', {variant: 'success'})
            }
        })
    }

    const generalDelete = (id) => {
        deleteData(id).then(() => {
            const restOfData = data.filter(r => r.id !== current)
            setData(restOfData)
            enqueueSnackbar('Törölve!', {variant: 'success'})
        })
    }

    const handleNew = () => {
        const formData = getFormData(formRef.current)
        generalNew(formData)
    }

    const handleEdit = () => {
        const formData = getFormData(formRef.current)
        generalEdit(formData)
    }

    const getObj = (id) => {
        return data.find(r => r.id === id)
    }

    useEffect(generalGetList, [])

    const onRowClick = () => {
        console.log('row cilcked')
    }

    const editForm = React.Children.map(form, i => {
        const obj = getObj(current)
        if (React.isValidElement(form)) {
            return React.cloneElement(form, {obj});
        }
        return form;
    });

    return <>
        <MiniDrawer title={pluralTitle}>
            <DataTable loading={loading} data={data} columns={columns} onRowClick={onRowClick}/>
            <BasicSpeedDial actions={speedDialActions}/>

            <ScrollDialog
                title={`Új ${title}`}
                open={newPopupOpen}
                onClose={() => (setNewPopupOpen(false))}
                onSave={() => {
                    handleNew()
                    setNewPopupOpen(false)
                }}>
                {form}
            </ScrollDialog>

            <ScrollDialog
                title={`${title} szerkesztése`}
                open={editPopupOpen}
                onClose={() => (setEditPopupOpen(false))}
                onSave={() => {
                    handleEdit()
                    setEditPopupOpen(false)
                }}>
                {editForm}
            </ScrollDialog>

            {extraScrollDialogs.map((e, i) => <ScrollDialog
                key={`scroll_${i}`}
                title={e.title}
                open={e.open}
                onClose={e.onClose}
                onSave={e.onSave}>
                {e.children}
            </ScrollDialog>)}

            <AlertDialog
                onNo={() => (setDeletePopupOpen(false))}
                onYes={() => {
                    setDeletePopupOpen(false)
                    generalDelete(current)
                }}
                open={deletePopupOpen}
                title={`Biztosan törli?`}
                content={`Nem vonható vissza.`}/>

            {extraAlertDialogs.map((d, i) => <AlertDialog
                key={`alert_${i}`}
                onNo={d.onNo}
                onYes={d.onYes}
                open={d.open}
                title={d.title}
                content={d.content}/>)}

        </MiniDrawer>
    </>
}

export default ListView