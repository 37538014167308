import {Grid, Typography, useMediaQuery} from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {useTheme} from "@mui/material/styles";
import trilogic from "../assets/trilogic-logo.png";
import simple from "../assets/simplepay-logo-color.png";
import szep from "../assets/otpszepkartya.png";
import {useContext, useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import ConfigContext from "../context/ConfigContext";


export default function Footer() {
    const theme = useTheme();
    const {apartmentId, baseUrl} = useContext(ConfigContext)

    const backUrl = baseUrl.slice(0, baseUrl.length - 1)

    const [contact, setContact] = useState({
        email: '',
        phone: '',
        facebook: '',
        instagram: '',
        aszf: '',
        data_handling: '',
        cookie: ''
    })
    const {getUnauthData} = useCallDataApi("contact");

    const getContact = () => {
        getUnauthData(`get_for_apartment?apartment=${apartmentId}`).then(r => setContact(r))
    }

    useEffect(getContact, [])


    const footerStyle = {
        display: 'flex',
        justifyContent: 'center',
        padding: '25px 0 25px 0',
        alignItems: 'center',
        background: theme.palette.primary.main,
        color: theme.palette.secondary.main
    }

    const centerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const isMobile = useMediaQuery('(min-width:600px)');
    const direction = () => isMobile ? 'row' : 'column';

    return (
        <footer>
            <Grid container spacing={2} style={footerStyle}>
                <Grid item container alignItems='center' justifyContent='space-evenly'>
                    {contact.facebook && <Grid item style={centerStyle}>
                        <a href={contact.facebook} style={{color: theme.palette.secondary.main}}>
                            <FacebookIcon/>
                        </a>
                    </Grid>}
                    {contact.instagram && <Grid item style={centerStyle}>
                        <a href={contact.instagram} style={{color: theme.palette.secondary.main}}>
                            <InstagramIcon/>
                        </a>
                    </Grid>}

                </Grid>
                <Grid item container alignItems='center' justifyContent='space-evenly' direction={direction()}>
                    <Grid item xs={6} md={4} style={centerStyle}>
                        <a href={backUrl + contact.aszf} target='_blank' rel='noopener' style={{color: theme.palette.secondary.main}}>
                            <Typography variant="subtitle1">ÁSZF</Typography>
                        </a>
                    </Grid>
                    <Grid item xs={6} md={4} style={centerStyle}>
                        <a href={backUrl + contact.data_handling} target='_blank' rel='noopener' style={{color: theme.palette.secondary.main}}>
                            <Typography variant="subtitle1">Adatkezelési tájékoztató</Typography>
                        </a>
                    </Grid>
                    <Grid item xs={6} md={4} style={centerStyle}>
                        <a href={backUrl + contact.cookie} target='_blank' rel='noopener' style={{color: theme.palette.secondary.main}}>
                            <Typography variant="subtitle1">Cookie kezelés</Typography>
                        </a>
                    </Grid>
                    <Grid item xs={6} md={6} style={centerStyle}>
                        <Typography variant="subtitle1">{contact.email}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} style={centerStyle}>
                        <Typography variant="subtitle1">{contact.phone}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} style={centerStyle}>
                        <a href="https://trilogic.hu/" style={centerStyle}>
                            <img style={{width: '25%', marginTop: '20px'}} src={trilogic} alt="trilogic logo"/>
                        </a>
                    </Grid>
                    <Grid item xs={6} md={6} style={centerStyle}>
                        <a href="https://simplepay.hu/" style={centerStyle}>
                            <img style={{width: '50%', marginTop: '20px'}} src={simple} alt="simple logo"/>
                        </a>
                    </Grid>
                    <Grid item xs={6} md={6} style={centerStyle}>
                        <img style={{width: '20%', marginTop: '20px'}} src={szep} alt="simple logo"/>
                    </Grid>
                </Grid>
            </Grid>

        </footer>
    )
}