import React, {useContext} from "react";
import AuthContext from "../../context/AuthContext";
import {Button, Card, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
// import logo from "../assets/Tourguide logo.png"

const LoginPage = () => {
    const {loginUser} = useContext(AuthContext);
    return (
        <Card className="login">
            <Typography variant="h4" style={{textAlign: 'center'}}>Bejelentkezés</Typography>
            <form className="form" onSubmit={loginUser}>
                <TextField
                    className="inp"
                    label="Felhasználónév"
                    id="username"
                    name="username"
                    type="text"
                    margin="dense"
                />

                <TextField
                    className="inp"
                    label="Jelszó"
                    id="password"
                    name="password"
                    type="password"
                    margin="dense"
                />

                <Button color="primary" variant="outlined" type="submit" className="form__custom-button">
                    Bejelentkezés
                </Button>
            </form>
        </Card>
    );
};

export default LoginPage;