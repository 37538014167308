import {TextField} from "@mui/material";
import React from "react";


const GalleryImageData = ({reference, obj}) => {

    return <>
        <form ref={reference}>
            {!obj && <TextField
                id="image"
                name="image"
                type="file"
                accept="image/jpeg,image/png,image/gif"
                multiple={false}
            />}
        </form>
    </>;
}

export default GalleryImageData