import ListView from "../../../components/Views/ListViewPage";
import {useContext, useEffect, useRef, useState} from "react";
import ReservationData from "./ReservationData";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import useCallDataApi from "../../../hooks/data";
import {useSnackbar} from "notistack";
import {thousandSeparator} from "../../../utils/utils";

const Reservations = () => {
    const {postData} = useCallDataApi('reservations-admin');
    const {getData} = useCallDataApi('rooms-admin');
    const [acceptReservationOpen, setAcceptReservationOpen] = useState(false)
    const [rooms, setRooms] = useState([])
    const [current, setCurrent] = useState(1)
    const reservationForm = useRef(null)
    const {enqueueSnackbar} = useSnackbar();

    const columns = [
        {field: "name", headerName: "Név", width: 200},
        {field: "guests", headerName: "Hány fő", width: 200},
        {field: "room", headerName: "Szoba", width: 200, renderCell: (params) => getRoom(params.row['room'])?.name},
        {field: "tax_number", headerName: "Adószám", width: 70},
        {field: "email", headerName: "Email", width: 70},
        {field: "start_date", headerName: "Kezdő dátum", width: 110},
        {field: "end_date", headerName: "Vég dátum", width: 110},
        {field: "phone", headerName: "Telefonszám", width: 110},
        {field: "address", headerName: "Cím", width: 110},
        {field: "price", headerName: "Ár", width: 110, renderCell: (params) => thousandSeparator(params.row['price']) + ' Ft'},
        {field: "date_reserved", headerName: "Foglalás dátuma", width: 110},
        {field: "accepted", headerName: "Elfogadva", width: 110, renderCell: (params) => params.row['accepted'] ? <CheckIcon/> : <CloseIcon/>},
        {field: "paid", headerName: "Fieztés ok", width: 110, renderCell: (params) => params.row['paid'] ? <CheckIcon/> : <CloseIcon/>}
    ]

    const acceptReservation = (id) => {
        postData(`${id}/accept_reservation/`, {})
            .then(() => enqueueSnackbar('Elfogadva!', {variant: 'success'}))
    }

    const getRooms = () => {
        getData()
            .then(r => setRooms(r))
    }

    const getRoom = (id) => {
        return rooms.find(r => r.id === id)
    }

    useEffect(getRooms, [])

    const extraColActions = [{
        icon: <CheckIcon/>,
        onClick: (id) => {
            setAcceptReservationOpen(true)
            setCurrent(id)
        },
        label: "Foglalás elfogadása",
        showInMenu: true,
        show: (params) => !params.row['accepted'] && !params.row['paid']
    }]

    const extraAlertDialogs = [{
        onNo: () => setAcceptReservationOpen(false),
        onYes: () => {
            setAcceptReservationOpen(false)
            acceptReservation(current)
        },
        open: acceptReservationOpen,
        title: `Biztosan elfogadja a foglalását?`,
        content: `Ez után fogja tudni kifizetni.`
    }]

    return <ListView
        title="Foglalás"
        baseSlug="reservations-admin"
        pluralTitle="Foglalások"
        propColumns={columns}
        extraColActions={extraColActions}
        extraAlertDialogs={extraAlertDialogs}
        formRef={reservationForm}
        form={<ReservationData reference={reservationForm}/>}/>
}

export default Reservations