import ListView from "../../../components/Views/ListViewPage";
import {useRef} from "react";
import CouponCodeData from "./CouponCodeData";

const CouponCodes = () => {
    const couponForm = useRef(null)
    const columns = [
        {field: "code", headerName: "Kód", width: 200},
        {field: "percent", headerName: "Kedvezmény százalék", width: 200,
            renderCell: (params) => (parseFloat(params.row['percent']) * 100).toString() + '%'},
        {field: "valid", headerName: "Érvényességi idő", width: 200},
    ];

    return <ListView
        title="Kuponkód"
        baseSlug="coupon-codes"
        pluralTitle="Kuponkódok"
        propColumns={columns}
        formRef={couponForm}
        form={<CouponCodeData reference={couponForm}/>}/>
}

export default CouponCodes