import {Editor} from '@tinymce/tinymce-react';
import {useEffect, useRef, useState} from "react";
import MiniDrawer from "../../../components/AdminDrawer";
import {Button, Stack} from "@mui/material";
import useCallDataApi from "../../../hooks/data";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";


const EmailTexts = () => {
    const {getData, updateData, deleteData, formDataCreateData, formDataUpdateData} = useCallDataApi('email-texts');
    const [reservationText, setReservationText] = useState({})
    const confirmRef = useRef(null);
    const couponCodeRef = useRef(null);
    const {enqueueSnackbar} = useSnackbar();

    const log = () => {
        // if (editorRef.current) {
        //     console.log(editorRef.current.getContent());
        // }
        editEmailTexts()
    };

    const getReservationTexts = () => {
        getData(`get_for_apartment?apartment=${1}`).then(r => setReservationText(r))
    }

    const editEmailTexts = () => {
        updateData(reservationText.id, {
            confirm: confirmRef.current.getContent(),
            coupon_code: couponCodeRef.current.getContent()
        })
            .then(r => {
                setReservationText(r)
                enqueueSnackbar('Szerkesztve!', {variant: 'success'})
            })
    }

    useEffect(getReservationTexts, [])

    const clipboardItems = [
        {
            name: 'Név',
            item: 'name'
        }, {
            name: 'Kezdő dátum',
            item: 'start_date'},
        {
            name: 'Vég dátum',
            item: 'end_date'
        }, {
            name: 'Email',
            item: 'email'
        }, {
            name: 'Telefonszám',
            item: 'phone'
        }, {
            name: 'Cím',
            item: 'address'
        }, {
            name: 'Ár',
            item: 'price'
        }, {
            name: 'Foglalás dátuma',
            item: 'date_reserved'
        }, {
            name: 'Fizetési link (ha kell)',
            item: 'payment_url'
        },]

    return <>
        <MiniDrawer>
            <Typography variant="h5">Változók:</Typography>
            <Stack spacing={2}>
                {clipboardItems.map((item, i) => <Button key={`cb_${i}`} variant="outlined" startIcon={<ContentCopyIcon/>} onClick={() => {
                        navigator.clipboard.writeText(`{{ ${item.item} }}`)
                        enqueueSnackbar('Másolva a vágólapra!', {variant: 'success'})
                    }}>{item.name}</Button>)}

            </Stack>
            <Typography variant="h5">Foglalás megerősítése email:</Typography>
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => confirmRef.current = editor}
                initialValue={reservationText?.confirm}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
            <Typography variant="h5">Kuponkódos fizetés email:</Typography>
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => couponCodeRef.current = editor}
                initialValue={reservationText?.coupon_code}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
            <Button style={{marginTop: '20px'}} variant='outlined' onClick={log}>Mentés</Button>
        </MiniDrawer>
    </>
}

export default EmailTexts