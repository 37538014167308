import {Editor} from '@tinymce/tinymce-react';
import {useEffect, useRef, useState} from "react";
import MiniDrawer from "../../../components/AdminDrawer";
import {Button} from "@mui/material";
import useCallDataApi from "../../../hooks/data";

import {useSnackbar} from "notistack";


const MainText = () => {
    const {getData, updateData, deleteData, formDataCreateData, formDataUpdateData} = useCallDataApi('reservation-texts');
    const [reservationText, setReservationText] = useState([])
    const editorRef = useRef(null);
    const {enqueueSnackbar} = useSnackbar();

    const log = () => {
        // if (editorRef.current) {
        //     console.log(editorRef.current.getContent());
        // }
        editMainText()
    };

    const getReservationTexts = () => {
        getData(`get_for_apartment?apartment=${1}`).then(r => setReservationText(r))
    }

    const editMainText = () => {
        updateData(reservationText.id, {text: editorRef.current.getContent()})
            .then(r => {
                setReservationText(r)
                enqueueSnackbar('Szerkesztve!', {variant: 'success'})
            })
    }

    useEffect(getReservationTexts, [])

    return <>
        <MiniDrawer>
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={reservationText.text}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
            <Button style={{marginTop: '20px'}} variant='outlined' onClick={log}>Mentés</Button>
        </MiniDrawer>
    </>
}

export default MainText