import * as React from 'react';
import {
    gridFilteredSortedRowIdsSelector,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarExport,
    GridToolbarFilterButton, gridVisibleColumnFieldsSelector, useGridApiContext, huHU
} from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import EMobiledataIcon from '@mui/icons-material/EMobiledata';
import {DataGridPro, LicenseInfo} from '@mui/x-data-grid-pro';
import * as XLSX from "xlsx";
import {LinearProgress} from "@mui/material";

export default function ColumnSelectorGrid(props) {

    LicenseInfo.setLicenseKey(
        process.env.REACT_APP_MUI_LICENSE
    );

    const CustomToolbar = () => {
        const apiRef = useGridApiContext();

        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Button
                    // className={classes.toolbar}
                    onClick={() => {exportJS(getData(apiRef))}}
                    startIcon={<EMobiledataIcon />}
                >EXCEL
                </Button>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const getData = (apiRef) => {
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

        return filteredSortedRowIds.map((id) => {
            const row = {};
            visibleColumnsField.forEach((field) => {
                row[field] = apiRef.current.getCellParams(id, field).value;
            });
            return row;
        });

    };

    const exportJS = (data) => {

        const fields = Object.keys(data[0]);

        const wb = XLSX.utils.book_new(); // book
        const ws = XLSX.utils.json_to_sheet(data, { header: fields }); // sheet

        XLSX.utils.book_append_sheet(wb, ws, "exported");
        XLSX.writeFile(wb, "exported.xlsx");
    };

    const data = {
        rows: props.data,
        columns: props.columns,
        rowLength: 10,
        maxColumns: 10,
    };

    return (
        <div style={{ height: '75vh', width: '100%' }}>
            <DataGridPro
                loading={props?.loading ? props.loading : false}
                {...data}
                density="compact"
                onRowClick={(param) => {props?.onRowClick(param.row)}}
                components={{
                    LoadingOverlay: LinearProgress,
                    Toolbar: CustomToolbar,
                }}
                localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
            />
        </div>
    );
}