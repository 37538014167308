import {TextField} from "@mui/material";
import React, {useState} from "react";

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import adapterLocale from 'date-fns/locale/en-GB'
import {DatePicker} from "@mui/x-date-pickers";


const CouponCodeData = ({reference, obj}) => {
    const [valid, setValid] = useState(obj?.valid || new Date())

    return <>
        <form ref={reference}>
            <TextField id="code" name="code" label="Kód" defaultValue={obj?.code || ''}/>
            <TextField id="percent" name="percent" label="Kedvezmény százakék" type="number" defaultValue={obj?.percent || ''}/>

            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
                <DatePicker
                    label="Érvényességi idő"
                    value={valid}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    onChange={(newValue) => {
                        setValid(newValue);
                    }}
                    renderInput={(params) => <TextField id="valid" name="valid" {...params} />}
                />
            </LocalizationProvider>
        </form>
    </>;
}

export default CouponCodeData