import ListView from "../../../components/Views/ListViewPage";
import {useRef} from "react";
import RoomData from "./RoomData";
import CheckIcon from "@mui/icons-material/Check";
import React, {useState} from "react";
import useCallDataApi from "../../../hooks/data";
import {useSnackbar} from "notistack";
import RoomPriceData from "./RoomPriceData";
import {getFormData} from "../../../utils/FormUtils";

const Rooms = () => {
    const roomForm = useRef(null)
    const {getData, postData} = useCallDataApi('rooms-admin');
    const [editRoomPriceOpen, setEditRoomPriceOpen] = useState(false)
    const [roomPrice, setRoomPrice] = useState({})
    const [current, setCurrent] = useState(1)
    const roomPriceForm = useRef(null)
    const {enqueueSnackbar} = useSnackbar();
    const columns = [
        {field: "name", headerName: "Név", width: 400},
        {field: "capacity", headerName: "Férőhelyek száma", width: 400},
    ];

    const editRoomPrice = (id, data) => {
        postData(`${id}/update_room_price/`, data)
            .then(r => {
                setRoomPrice(r)
                enqueueSnackbar('Frissítve!', {variant: 'success'})
            })
    }

    const getRoomPrice = (id) => {
        getData(`${id}/get_room_price/`)
            .then(r => {
                setRoomPrice(r)
                setEditRoomPriceOpen(true)
            })
    }

    const extraColActions = [{
        icon: <CheckIcon/>,
        onClick: (id) => {
            getRoomPrice(id)
        },
        label: "Árak módosítása",
        showInMenu: true,
        show: (params) => true
    }]

    const extraScrollDialogs = [{
        onClose: () => setEditRoomPriceOpen(false),
        onSave: () => {
            setEditRoomPriceOpen(false)
            editRoomPrice(roomPrice.id, getFormData(roomPriceForm.current))
        },
        open: editRoomPriceOpen,
        title: `Szobaárak módosítása`,
        children: <RoomPriceData reference={roomPriceForm} obj={roomPrice}/>
    }]

    return <ListView
        title="Szoba"
        baseSlug="rooms-admin"
        pluralTitle="Szobák"
        propColumns={columns}
        formRef={roomForm}
        extraColActions={extraColActions}
        extraScrollDialogs={extraScrollDialogs}
        form={<RoomData reference={roomForm}/>}/>
}

export default Rooms