import {Stack, TextField} from "@mui/material";
import React from "react";



const AttractionData = ({reference, obj}) => {
    return <>
        <form ref={reference}>
            <Stack>
                <TextField id="name" name="name" label="Név" defaultValue={obj?.name || ''} style={{width: '100%'}}/>

                <TextField id="description" name="description" label="Leírás" multiline rows={6} defaultValue={obj?.description || ''} style={{width: '100%'}}/>
                <TextField id="image"
                           name="image"
                           type="file"
                           accept="image/jpeg,image/png,image/gif"
                           multiple={false} style={{width: '100%'}}/>
            </Stack>

        </form>
    </>;
}

export default AttractionData