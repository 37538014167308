import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import adapterLocale from 'date-fns/locale/en-GB'
import {DatePicker} from "@mui/x-date-pickers";
import useCallDataApi from "../../../hooks/data";


export default function ReservationData({reference, obj}) {
    const [startDate, setStartDate] = useState(obj?.start_date || new Date())
    const [endDate, setEndDate] = useState(obj?.end_date || new Date())
    const [rooms, setRooms] = useState([])
    const [room, setRoom] = useState(obj?.room || '')
    const {getData} = useCallDataApi("rooms-admin");

    const getRooms = () => {
        getData().then(r => setRooms(r))
    }

    useEffect(getRooms, [])

    const handleRoomChange = (event) => {
        setRoom(event.target.value)
    }

    return <>
        <form ref={reference}>
            <TextField id="name" name="name" label="Név" defaultValue={obj?.name || ''}/>
            <TextField id="tax_number" name="tax_number" label="Adószám" defaultValue={obj?.tax_number || ''}/>
            <TextField id="email" name="email" label="Email" defaultValue={obj?.email || ''}/>
            <TextField id="phone" name="phone" label="Telefonszám" defaultValue={obj?.phone || ''}/>
            <TextField id="address" name="address" label="Cím" defaultValue={obj?.address || ''}/>
            <TextField id="price" name="price" label="Ár" defaultValue={obj?.price || ''}/>
            <TextField id="guests" name="guests" label="Hány fő?" defaultValue={obj?.guests || ''}/>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
                <DatePicker
                    label="Kezdő dátum"
                    value={startDate}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    onChange={(newValue) => {
                        setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField id="start_date" name="start_date" {...params} />}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
                <DatePicker
                    label="Vég dátum"
                    value={endDate}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    onChange={(newValue) => {
                        setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField id="end_date" name="end_date" {...params} />}
                />
            </LocalizationProvider>
            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id="return-types">Szoba</InputLabel>
                <Select
                    labelId="return-types"
                    label="Szoba"
                    id="room"
                    name="room"
                    value={room}
                    onChange={handleRoomChange}
                >
                    {rooms.map(r => <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>)}
                </Select>
            </FormControl>
        </form>
    </>;
}