import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';


const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
    },
}));


export default function BasicSpeedDial(props) {
    return (
        <Box onClick={() => {props?.onClick && props?.onClick()}} sx={{ position: 'fixed', bottom: '10px', right: '10px',transform: 'translateZ(0px)', flexGrow: 1 }}>
            <Box sx={{ position: 'fixed', bottom: '10px', right: '10px', mt: 3 }}>
                <StyledSpeedDial
                    ariaLabel="Options"
                    icon={<SpeedDialIcon />}
                >
                    {props.actions?.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                        />
                    ))}
                </StyledSpeedDial>
            </Box>
        </Box>
    );
}