import {useContext} from "react";
import AuthContext from "../context/AuthContext";

export function useAuthHeader() {
    const {authTokens} = useContext(AuthContext);
    return {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + String(authTokens?.access),
        },
        unauthenticatedHeaders: {
            "Content-Type": "application/json",
        },
        formDataHeaders: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + String(authTokens?.access),
        },
    };
}