import CardView from "../../../components/Views/CardView";
import {Button, Card, CardActions, CardContent, CardMedia, Typography} from "@mui/material";
import {useRef} from "react";
import GalleryImageData from "./GalleryImageData";


export const PropCard = ({obj, editHandler = null, deleteHandler = null}) => <Card sx={{maxWidth: 345, height: '100%'}}>
    <CardMedia
        component="img"
        height="140"
        image={obj?.image}
        alt={obj?.name}
    />
    <CardContent>
        <Typography gutterBottom variant="h5" component="div">
            {obj?.name}
        </Typography>
    </CardContent>
    <CardActions>
        <Button size="small" variant="outlined" color="error" onClick={deleteHandler}>Törlés</Button>
    </CardActions>
</Card>


const GalleryImages = () => {
    const galleryImageForm = useRef(null)
    return <CardView
        title='képgaléria'
        imageFiledName="image"
        pluralTitle='Galéria képek'
        baseSlug='gallery'
        PropCard={PropCard}
        formRef={galleryImageForm}
        form={<GalleryImageData reference={galleryImageForm}/>}
    />
}

export default GalleryImages