import Carousel from "react-material-ui-carousel";
import React, {useEffect, useState, useContext} from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import loading from '../assets/loading.png'
import useCallDataApi from "../hooks/data";
import ConfigContext from "../context/ConfigContext";


export default function MainCarousel({apartment}) {
    const [images, setImages] = useState([{name: 'loading', image: loading}])
    const {getUnauthData} = useCallDataApi("reservations");
    const {baseUrl, apartmentId} = useContext(ConfigContext)

    const getImages = () => {
        getUnauthData(`gallery_images/?apartment=${apartmentId}`)
            .then(r => setImages(r))
    }

    useEffect(getImages, [])

    return <div id='kepek'>
        <Carousel
            fullHeightHover={true}
            navButtonsProps={{
                style: {
                    borderRadius: 0
                }
            }}
            navButtonsAlwaysVisible={true}
            NextIcon={<ChevronRightIcon/>}
            PrevIcon={<ChevronLeftIcon/>}
        >
            {images.map((item, i) =>
                <img key={`image_${i}`}
                     src={baseUrl.substring(0, baseUrl.length - 1) + item.image}
                     alt={item.name} style={{width: '100%', aspectRatio: '16 / 9'}}/>)}
        </Carousel>
    </div>;
}