import React, {useEffect, useState} from "react";
import MyDrawer from "../../components/Drawer";
import Footer from "../../components/Footer";
import Typography from "@mui/material/Typography";
import {useSearchParams} from "react-router-dom";
import useCallDataApi from "../../hooks/data";
import {Button, CircularProgress} from "@mui/material";
import happyHouse from "../../assets/happy house.png"
import sadHouse from "../../assets/sad house.png"
import Box from "@mui/material/Box";

export default function Success() {
    const [searchParams, setSearchParams] = useSearchParams();
    const {postData} = useCallDataApi("reservations");
    const [state, setState] = useState({})
    const [loading, setLoading] = useState(true)

    const finishPayment = () => {
        if (!searchParams.get('r')) {
            setState({state: 'Sikeres foglalás!'})
            setLoading(false)
        } else {
            postData('success/', {result: searchParams.get('r'), signature: searchParams.get('s')})
                .then(r => {
                    setState(r)
                    setLoading(false)
                })
        }

    }

    const backToHome = () => {
        window.location.href = '/'
    }

    useEffect(() => {
        finishPayment()
    }, [searchParams])

    const CenterContainer = ({children}) => {
        return (
            <div className="center-container">
                {children}
            </div>
        )
    }

    const FullHeight = ({children}) => {
        return (
            <div className="full-height">
                {children}
            </div>
        )
    }


    return <MyDrawer title={state?.state ? state?.state : 'Kérjük várjon!'}>
        <FullHeight/>
        <CenterContainer>
            {loading ?
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box>
                :
                <>{state?.state === 'Sikeres foglalás!' ? <img src={happyHouse} alt="happy house"/> :
                    <img src={sadHouse} alt="sad house"/>}
                    <Typography variant="h6" style={{textAlign: 'center'}}>
                        {state?.state ? state?.state : 'Érvénytelen link'}
                    </Typography>
                    <Typography variant="body2" style={{textAlign: 'center'}}>
                        {state?.state === 'Sikeres foglalás!' ? 'A megadott e-mail címre kiküldtük a foglalás részleteit.' : '-'}
                    </Typography>
                    <Typography variant="subtitle1" style={{textAlign: 'center'}}>
                        {state?.transaction_id ? state?.transaction_id : '-'}
                    </Typography>
                    <Button variant="outlined" onClick={backToHome} style={{width: '100%', margin: 'auto 0'}}>Vissza a
                        főoldalra</Button>
                </>}
        </CenterContainer>
        <Footer/>
    </MyDrawer>
}