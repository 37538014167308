import {TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import useCallDataApi from "../../../hooks/data";


const RoomPriceData = ({reference, obj}) => {
    const {getData} = useCallDataApi('rooms-admin');
    const [capacity, setCapacity] = useState(1)

    const getRoom = () => {
        getData(`${obj.room}/`).then(room => setCapacity(room?.capacity))
    }

    useEffect(() => getRoom(), [])

    return <>
        <form ref={reference}>
            {Array.apply(null, {length: capacity}).map((e, i) =>
                <TextField key={i} id={`${i+1}`} name={`${i+1}`} type="number" label={`Ár ${i+1} főre`} defaultValue={obj?.prices[i+1] || ''}/>
            )}
        </form>
    </>;
}

export default RoomPriceData

