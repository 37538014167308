import {Checkbox, FormControlLabel, FormGroup, TextField} from "@mui/material";
import React, {useState} from "react";

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import adapterLocale from 'date-fns/locale/en-GB'
import {DatePicker} from "@mui/x-date-pickers";


const EventData = ({reference, obj}) => {
    const [startDate, setStartDate] = useState(obj?.start_date || new Date())
    const [endDate, setEndDate] = useState(obj?.end_date || new Date())


    return <>
        <form ref={reference}>
            <TextField id="name" name="name" label="Név" defaultValue={obj?.name || ''}/>
            <TextField id="percent" name="percent" label="Százalék" type="number" defaultValue={obj?.percent || ''}/>

            <TextField id="description" name="description" label="Leírás" defaultValue={obj?.description || ''}/>
            <TextField id="image"
                       name="image"
                       type="file"
                       accept="image/jpeg,image/png,image/gif"
                       multiple={false}/>

            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
                <DatePicker
                    label="Kezdő dátum"
                    value={startDate}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    onChange={(newValue) => {
                        setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField id="start_date" name="start_date" {...params} />}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
                <DatePicker
                    label="Vég dátum"
                    value={endDate}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    onChange={(newValue) => {
                        setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField id="end_date" name="end_date" {...params} />}
                />
            </LocalizationProvider>
        </form>
    </>;
}

export default EventData