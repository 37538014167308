import {Navigate, Outlet} from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const PrivateRoute = () => {
    const { user } = useContext(AuthContext);
    return user ? <Outlet /> : <Navigate to="/login" />;
};

/*
//app.js
<Route path='/reservations' element={<PrivateRoute/>}>
    <Route element={<Reservations/>} path="/reservations"/>
</Route>


//private route
const PrivateRoute = () => {
    const { user } = useContext(AuthContext);
    console.log({user})
    //return user ? children : <Route element={<LoginPage/>} path="/login"/>;
    //return user ? <Route></Route> : <Navigate to="/login"/>;
    return user ? <Outlet /> : <Navigate to="/login" />;
};

*/

export default PrivateRoute;