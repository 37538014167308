import {Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, TextField} from "@mui/material";
import LocationInput from "./LocationInput";


export default function CompanyData({reference, handle}) {
    return (<form ref={reference}>
        <Grid container spacing={2}>
            <Grid item xs={6} md={6} >
                <TextField name="company" style={{'width': '100%'}} label="Cég neve"/>
            </Grid>
            <Grid item xs={6} md={6}>
                <TextField name="tax" style={{'width': '100%'}} label="Adószám"/>
            </Grid>
            <LocationInput/>
            <Grid item xs={12}>
                <TextField name="email" style={{'width': '100%'}} label="Email"/>
            </Grid>
            <Grid item xs={12}>
                <TextField name="phone" style={{'width': '100%'}} label="Telefonszám"/>
            </Grid>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel control={<Checkbox name='agree_field' />} label="Elfogadom az adatkezelési szabályzatban foglaltakat" />
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" style={{float: 'right'}} onClick={() => handle('company')}>Foglalás</Button>
            </Grid>
        </Grid>


    </form>)
}