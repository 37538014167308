const getFormData = (form) => {
    const formData = new FormData(form)
    let object = {};
    formData.forEach((value, key) => {
        if (value !== "") object[key] = value
        if (value === "on") object[key] = true
    });
    return object;
}

const getFormDataNative = (form, extra=[]) => {
    let formData = new FormData(form);
    extra.forEach(e => formData.append(e.key, e.value));
    return formData;
}

const getFormDataWithImage = (form, img, extra) => {
    let formData = new FormData(form)
    if (img) formData.append("image", img, img.name);
    extra.forEach(e => formData.append(e.key, e.value));
    return formData;
}

export {getFormData, getFormDataNative, getFormDataWithImage}