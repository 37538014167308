import MiniDrawer from "../../../components/AdminDrawer";
import {Button, Stack, TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import adapterLocale from "date-fns/locale/en-GB";
import {DatePicker} from "@mui/x-date-pickers";
import {useEffect, useRef, useState} from "react";
import useCallDataApi from "../../../hooks/data";
import {useSnackbar} from "notistack";
import {format} from "date-fns";
import Typography from "@mui/material/Typography";
import {getFormData} from "../../../utils/FormUtils";

const AdminSettings = () => {
    const {getData, postData} = useCallDataApi('apartments-admin');
    const {getData: getContact, formDataUpdateData} = useCallDataApi('contact');
    const [maxDate, setMaxDate] = useState(new Date())
    const [contactData, setContactData] = useState({})
    const contactForm = useRef(null)
    const {enqueueSnackbar} = useSnackbar();

    const getUntilDate = () => {
        getData(`get_until_date`).then(r => setMaxDate(r.date))
    }

    const getContactData = () => {
        getContact().then(r => {
            setContactData(r[0])
        })
    }

    const updateMaxDate = (date) => {
        postData('update_until_date/', {date: format(date, 'yyyy-MM-dd')}).then(r => {
            setMaxDate(r.date)
            enqueueSnackbar('Dátum frissítve', {variant: "success"})
        })
    }

    useEffect(() => {
        getUntilDate()
        getContactData()
    }, [])

    const editContactForm = () => {
        const formData = getFormData(contactForm.current)
        if (formData.aszf.size === 0) delete formData.aszf
        if (formData.cookie.size === 0) delete formData.cookie
        if (formData.data_handling.size === 0) delete formData.data_handling
        formDataUpdateData(contactData.id, formData).then(r => {
            setContactData(r)
            enqueueSnackbar('Szerkesztve!', {variant: 'success'})
        })
    }

    const handleInputChange = (e) => {
        setContactData({...contactData, [e.target.name]: e.target.value})
    }


    return <MiniDrawer title="Beállítások">
        <form>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
                <DatePicker
                    label="Max dátum"
                    value={maxDate}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    onChange={(newValue) => {
                        updateMaxDate(newValue);
                    }}
                    renderInput={(params) => <TextField id="until_date" name="until_date" {...params} />}
                />
            </LocalizationProvider>
        </form>
        <hr/>
        <form ref={contactForm}>
            <Stack spacing={2}>
                <TextField label="Telefonszám" name="phone" value={contactData?.phone || ''}
                           onChange={handleInputChange}/>
                <TextField label="Email" name="email" value={contactData?.email || ''} onChange={handleInputChange}/>
                <TextField label="Instagram" name="instagram" value={contactData?.instagram || ''}
                           onChange={handleInputChange}/>
                <TextField label="Facebook" name="facebook" value={contactData?.facebook || ''}
                           onChange={handleInputChange}/>
                <Typography variant="body2">ASZF: (Jelenleg: <a href={contactData?.aszf} target="_blank">{contactData?.aszf}</a>)</Typography>
                <TextField id="aszf"
                           name="aszf"
                           type="file"
                           inputProps={{accept: "application/pdf"}}
                           multiple={false}/>
                <Typography variant="body2">Adatkezelési tájékoztató: (Jelenleg: <a href={contactData?.data_handling} target="_blank">{contactData?.data_handling}</a>)</Typography>
                <TextField id="data_handling"
                           name="data_handling"
                           type="file"
                           inputProps={{accept: "application/pdf"}}
                           multiple={false}/>
                <Typography variant="body2">Cookie kezelés: (Jelenleg: <a href={contactData?.cookie} target="_blank">{contactData?.cookie}</a>)</Typography>
                <TextField id="cookie"
                           name="cookie"
                           type="file"
                           inputProps={{accept: "application/pdf"}}
                           multiple={false}/>
                <Button variant="outlined" color="success" onClick={editContactForm}>Mentés</Button>
            </Stack>

        </form>

    </MiniDrawer>
}

export default AdminSettings