import addDays from 'date-fns/addDays';
import {endOfWeek, format, parseISO, startOfWeek} from 'date-fns';
import {useEffect, useState} from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Grid, IconButton, Tooltip, useMediaQuery} from '@mui/material';
import {enGB} from 'date-fns/locale';
import {ArrowRightAlt} from "@mui/icons-material";

const Calendar = ({name, reservedDateRanges, extraDateRanges, minDate, maxDate, locale, data}) => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [highlightedDate, setHighlightedDate] = useState(new Date())
    const [endDateSelected, setEndDateSelected] = useState(false)
    const [startDateSelected, setStartDateSelected] = useState(false)

    const [reservedDates, setReservedDates] = useState(new Set())
    const [extraDates, setExtraDates] = useState({})

    const [currentMonth, setCurrentMonth] = useState((new Date()).getMonth())
    const [currentYear, setCurrentYear] = useState((new Date()).getFullYear())
    const isDesktop = useMediaQuery('(min-width:900px)');
    //TODO: extraDates should be a set as well

    useEffect(() => {
        createReservations(reservedDateRanges)
        //setExtraDates(extraDateRanges)
        createExtraDates(extraDateRanges)
    }, [reservedDateRanges, extraDateRanges])

    Calendar.defaultProps = {
        locale: {enGB}
    }

    const formatHuman = (date) => {
        return format(date, 'yyyy-MM-dd')
    }

    const createReservations = (reservedDateRanges) => {
        const reserved = new Set()
        for (let range of reservedDateRanges) {
            for (let i = addDays(parseISO(range['start_date']), 1); i < parseISO(range['end_date']); i = addDays(i, 1)) {
                reserved.add(formatHuman(i))
            }
        }
        setReservedDates(reserved)
    }

    const createExtraDates = (extraDateRanges) => {
        const extra = {}
        for (let range of extraDateRanges) {
            for (let i = (parseISO(range['start_date'])); i < parseISO(range['end_date']); i = addDays(i, 1)) {
                //extra.add({formatHuman(i): {'price': range['price'], 'name': range['name'] }})

                extra[formatHuman(i)] = range['name']
            }
        }
        setExtraDates(extra)
    }

    const paddedMonth = () => {
        const firstDay = new Date(currentYear, currentMonth, 1);
        const lastDay = new Date(currentYear, currentMonth + 1, 0);
        const startDay = firstDay.getDay();

        // Calculate the padding based on the start day of the month
        const padLength = startDay === 0 ? 6 : startDay - 1;
        const l = new Array(padLength).fill(0);

        // Loop through the days of the month
        for (let i = 1; i <= lastDay.getDate(); i++) {
            l.push(i);
        }
        return l;
    }

    const nextPaddedMonth = () => {
        const firstDay = new Date(currentYear, currentMonth + 1, 1);
        const lastDay = new Date(currentYear, currentMonth + 2, 0)
        const startDay = firstDay.getDay();

        // Calculate the padding based on the start day of the month
        const padLength = startDay === 0 ? 6 : startDay - 1;
        const l = new Array(padLength).fill(0);

        // Loop through the days of the month
        for (let i = 1; i <= lastDay.getDate(); i++) {
            l.push(i);
        }
        return l;
    }

    const nextWeeks = () => {
        let r = []
        const month = nextPaddedMonth()
        const chunkSize = 7;
        for (let i = 0; i < month.length; i += chunkSize) {
            r.push(month.slice(i, i + chunkSize))
        }
        return r
    }

    const weeks = () => {
        let r = []
        const month = paddedMonth()
        const chunkSize = 7;
        for (let i = 0; i < month.length; i += chunkSize) {
            r.push(month.slice(i, i + chunkSize))
        }
        return r
    }

    const showDay = (day) => {
        if (day === 0) return ''
        return day
    }

    const prevMonth = () => {
        if (currentMonth === 0) {
            setCurrentYear(currentYear => currentYear - 1)
            setCurrentMonth(11)
            return
        }
        setCurrentMonth(currentMonth => (currentMonth - 1) % 12)
    }

    const nextMonth = () => {
        if (currentMonth === 11) {
            setCurrentYear(currentYear => currentYear + 1)
        }
        setCurrentMonth(currentMonth => (currentMonth + 1) % 12)
    }

    const handleMouseOver = (month, day) => {
        const selectedDate = new Date(currentYear, month, day)
        if (afterMaxDate(selectedDate) || beforeMinDate(selectedDate) || isReserved(selectedDate)) return;
        if (startDateSelected || !endDateSelected) {
            setHighlightedDate(selectedDate)
        }
    }

    const handleClick = (month, day) => {
        const selectedDate = new Date(currentYear, month, day)
        if (afterMaxDate(selectedDate) || beforeMinDate(selectedDate) || isReserved(selectedDate)) return;
        if (!startDateSelected) { // None selected
            setStartDate(selectedDate)
            setStartDateSelected(true)
        } else if (!endDateSelected) { // Start selected
            if (startDate > selectedDate) { // End before start
                setEndDate(startDate)
                setStartDate(selectedDate)
                setEndDateSelected(true)
                return
            }
            setEndDate(selectedDate)
            setEndDateSelected(true)
        } else { // Start and End are selected
            setStartDate(selectedDate)
            setEndDateSelected(false)
        }
    }

    // Weekdays for every locale
    const getWeekdays = () => {
        let res = []
        const now = new Date();
        const monday = addDays(startOfWeek(now), 1)
        const sunday = addDays(endOfWeek(now), 1)
        for (let i = monday; i <= sunday; i = addDays(i, 1)) {
            res.push(format(i, 'EEEEEE', {locale: locale}))
        }
        return res
    }

    // Methods to decide className
    const isReserving = (date) => {
        if (startDateSelected && endDateSelected) {
            if (startDate <= date && date <= endDate) {
                return true
            }
        }
        return false
    }

    const afterMaxDate = (date) => {
        const maxDateGiven = parseISO(maxDate)
        return maxDateGiven < date
    }

    const beforeMinDate = (date) => {
        const minDateGiven = parseISO(minDate)
        return date < minDateGiven
    }

    const isReserved = (date) => {
        const dateReserved = reservedDates.has(formatHuman(date));
        const dateBeforeReserved = reservedDates.has(formatHuman(addDays(date, -1)));
        const dateAfterReserved = reservedDates.has(formatHuman(addDays(date, 1)));

        return (dateBeforeReserved && !dateReserved && dateAfterReserved) || dateReserved
    }

    const isExtra = (date) => {
        return formatHuman(date) in extraDates
    }

    const isExtraToShow = (month, day) => {
        const thisDay = new Date(currentYear, month, day)
        return isExtra(thisDay)
    }

    const getExtraName = (month, day) => {
        const thisDay = new Date(currentYear, month, day)
        return extraDates[formatHuman(thisDay)]
    }

    const isHighlighted = (date) => {
        if (startDateSelected && !endDateSelected) {
            if (startDate <= date && date <= highlightedDate) {
                return true
            } else if (startDate >= date && date >= highlightedDate) {
                return true
            }
        }
        return false
    }


    const getClassNames = (month, day) => {
        if (day === 0) return '';

        const classList = ['free-day'];
        const thisDay = new Date(currentYear, month, day);

        if (isHighlighted(thisDay) || isReserving(thisDay)) classList.push('reserving-day');
        if (afterMaxDate(thisDay) || beforeMinDate(thisDay)) classList.push('disabled');
        if (isReserved(thisDay)) classList.push('reserved-day');
        if (isExtra(thisDay)) classList.push('extra-day');

        return classList.join(' ');
    };


    const tdStyle = {
        textAlign: 'center',
        height: '40px',
        width: '40px',
    }

    const arrowStyle = {
        display: 'flex',
        justifyContent: 'center',
        padding: '5px',
        margin: 'auto',
    }

    const dateSelectStyle = {
        display: 'flex',
        justifyContent: 'center',
    }

    const chipStyle = {
        display: 'flex',
        justifyContent: 'center',
        minWidth: '100px'
    }

    const calendarStyle = {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    }

    const getThisMonth = () => {
        return format(new Date(currentYear, currentMonth, 1), 'LLLL', {locale: locale})
    }

    const getNextMonth = () => {
        return format(new Date(currentYear, currentMonth + 1, 1), 'LLLL', {locale: locale})
    }

    const getThisYear = () => {
        return format(new Date(currentYear, currentMonth, 1), 'yyyy', {locale: locale})
    }
    const getCorrectNextYear = () => {
        return currentMonth === 11 ? currentYear + 1 : currentYear
    }

    return (
        <Card id='naptar'>
            <CardContent>
                <form ref={data}>
                    <input type="hidden" name="startDate" value={formatHuman(startDate)}/>
                    <input type="hidden" name="endDate" value={formatHuman(endDate)}/>
                </form>
                <Typography gutterBottom variant='h5' component='div'>
                    {name ? name : 'Calendar'}
                </Typography>

                <Grid container spacing={2} justifyContent="center" alignItems="center"
                      direction={isDesktop ? "row" : "column"}>
                    <Grid item xs={12} md={12}>
                        <Grid container direction={isDesktop ? "row" : "column"} justifyContent="center"
                              alignItems="center">
                            <Grid item md={4} style={chipStyle}>
                                <Chip label={format(startDate, 'yyyy-MM-dd')} variant='outlined'/>
                            </Grid>
                            <Grid item md={4} style={arrowStyle}>
                                <ArrowRightAlt/>
                            </Grid>
                            <Grid item md={4} style={chipStyle}>
                                <Chip label={format(endDate, 'yyyy-MM-dd')} variant='outlined'/>
                            </Grid>
                        </Grid>
                    </Grid>

                    {!isDesktop ?
                        <>
                            <Grid item xs={12}>
                                <h3>{currentYear}</h3>
                            </Grid>
                            <Grid item xs={12} md={12} style={dateSelectStyle}>
                                <CardActions>
                                    <IconButton aria-label='delete' size='large' onClick={prevMonth}>
                                        <ChevronLeftIcon fontSize='inherit'/>
                                    </IconButton>
                                    {getThisMonth()}
                                    <IconButton aria-label='delete' size='large' onClick={nextMonth}>
                                        <ChevronRightIcon fontSize='inherit'/>
                                    </IconButton>
                                </CardActions>
                            </Grid>
                        </>
                        : <></>
                    }
                    {isDesktop ? <>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={12} md={1}>
                                <CardActions>
                                    <IconButton aria-label='delete' size='large' onClick={prevMonth}>
                                        <ChevronLeftIcon fontSize='inherit'/>
                                    </IconButton>
                                </CardActions>
                            </Grid>
                            <Grid item md={5} style={dateSelectStyle}>
                                <h3>{getThisYear() + " " + getThisMonth()}</h3>
                            </Grid>
                            <Grid item md={5} style={dateSelectStyle}>
                                <h3>{getCorrectNextYear() + " " + getNextMonth()}</h3>
                            </Grid>

                            <Grid item xs={12} md={1}>
                                <CardActions>
                                    <IconButton aria-label='delete' size='large' onClick={nextMonth}>
                                        <ChevronRightIcon fontSize='inherit'/>
                                    </IconButton>
                                </CardActions>
                            </Grid>
                        </Grid>
                    </> : <></>
                    }

                </Grid>

                <hr/>
                <div style={calendarStyle}>
                    <table style={{
                        width: isDesktop ? '50%' : '100%',
                        padding: '3%',
                        margin: 'auto',
                    }}>
                        <thead>
                        <tr>
                            {getWeekdays().map(day => <th key={day}>{day}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {weeks().map((week, index) => <tr key={'1week_' + index}>
                            {week.map((day, index) => isExtraToShow(currentMonth, day) ?
                                <Tooltip title={getExtraName(currentMonth, day)} key={'1extra_day_' + index}>
                                    <td onClick={() => handleClick(currentMonth, day)}
                                        onMouseOver={() => handleMouseOver(currentMonth, day)}
                                        className={getClassNames(currentMonth, day)}
                                        style={tdStyle}
                                    >
                                        {showDay(day)}
                                    </td>
                                </Tooltip>
                                :
                                <td key={'1day_' + index}
                                    onClick={() => handleClick(currentMonth, day)}
                                    onMouseOver={() => handleMouseOver(currentMonth, day)}
                                    className={getClassNames(currentMonth, day)}
                                    style={tdStyle}
                                >
                                    {showDay(day)}
                                </td>)}
                        </tr>)}
                        </tbody>
                    </table>

                    {!isDesktop ? <></> :
                        <table style={{
                            width: '50%',
                            padding: '3%',
                            // margin: 'auto',
                        }}>
                            <thead>
                            <tr>
                                {getWeekdays().map(day => <th key={day}>{day}</th>)}
                            </tr>
                            </thead>
                            <tbody>
                            {nextWeeks().map((week, index) => <tr key={'2week_' + index}>
                                {week.map((day, index) => isExtraToShow(currentMonth + 1, day) ?
                                    <Tooltip title={getExtraName(currentMonth + 1, day)} key={'2extra_day_' + index}>
                                        <td onClick={() => handleClick(currentMonth + 1, day)}
                                            onMouseOver={() => handleMouseOver(currentMonth + 1, day)}
                                            className={getClassNames(currentMonth + 1, day)}
                                            style={tdStyle}
                                        >
                                            {showDay(day)}
                                        </td>
                                    </Tooltip>
                                    :
                                    <td key={'2day_' + index}
                                        onClick={() => handleClick(currentMonth + 1, day)}
                                        onMouseOver={() => handleMouseOver(currentMonth + 1, day)}
                                        className={getClassNames(currentMonth + 1, day)}
                                        style={tdStyle}
                                    >
                                        {showDay(day)}
                                    </td>)}
                            </tr>)}
                            </tbody>
                        </table>
                    }
                </div>

            </CardContent>

        </Card>
    );
}

export default Calendar;