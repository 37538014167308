import React from "react";
import MyDrawer from "../../components/Drawer";
import Footer from "../../components/Footer";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import happyHouse from "../../assets/happy house.png"


const CouponCodeActivated = () => {
    const backToHome = () => {
        window.location.href = '/'
    }

    const CenterContainer = ({children}) => {
        return (
            <div className="center-container">
                {children}
            </div>
        )
    }

    const FullHeight = ({children}) => {
        return (
            <div className="full-height">
                {children}
            </div>
        )
    }


    return <MyDrawer title="Kupon kód!!!">
        <FullHeight/>
        <CenterContainer>
            <img src={happyHouse} alt="happy house"/>
            <Typography variant="h6" style={{textAlign: 'center'}}>
                Kupon kód!!!
            </Typography>
            <Typography variant="subtitle1" style={{textAlign: 'center'}}>
                Foglalását rögzítettük, a szállás üzemeltetője dönt a kuponkód elfogadásáról ezután emailben egy fizetési linket fog kapni.
            </Typography>
            <Button variant="outlined" onClick={backToHome} style={{width: '100%', margin: 'auto 0'}}>Vissza a
                főoldalra</Button>
        </CenterContainer>
        <Footer/>
    </MyDrawer>
}

export default CouponCodeActivated