import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PersonData from "./PersonData";
import CompanyData from "./CompanyData";


// @ts-ignore
export default function CenteredTabs({personData, companyData, handleReserve}) {
    const [value, setValue] = React.useState('person');


    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };




    return (
        <Box sx={{ width: '100%', typography: 'body1'}} id='foglalas'>
            <TabContext value={value} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                        <Tab label="Magánszemély" value="person" />
                        <Tab label="Cég" value="company" />
                    </TabList>
                </Box>
                <TabPanel value="person">
                    <PersonData reference={personData} handle={handleReserve}/>
                </TabPanel>
                <TabPanel value="company">
                    <CompanyData reference={companyData} handle={handleReserve}/>
                </TabPanel>
            </TabContext>
        </Box>
    );
}