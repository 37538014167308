import useCallDataApi from "../../hooks/data";
import React, {useEffect, useRef, useState} from "react";
import {Backdrop, CircularProgress, Grid} from "@mui/material";
import {useSnackbar} from "notistack";
import AlertDialog from "../../components/AlertDialog";
import ScrollDialog from "../ScrollDialog";
import BasicSpeedDial from "../SpeedDial";
import AddIcon from "@mui/icons-material/Add";
import {getFormData, getFormDataWithImage} from "../../utils/FormUtils";
import MiniDrawer from "../AdminDrawer";


const CardView = ({
                      baseSlug,
                      getSlug = '',
                      title,
                      pluralTitle,
                      formRef,
                      imageFiledName = null,
                      form,
                      propColumns = [],
                      extraColActions = [],
                      extraAlertDialogs = [],
                      extraSpeedDialActions = [],
                      PropCard
                  }) => {
    const {getData, updateData, deleteData, formDataCreateData, formDataUpdateData} = useCallDataApi(baseSlug);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [current, setCurrent] = useState(1)
    const [newPopupOpen, setNewPopupOpen] = useState(false)
    const [editPopupOpen, setEditPopupOpen] = useState(false)
    const [deletePopupOpen, setDeletePopupOpen] = useState(false)
    const {enqueueSnackbar} = useSnackbar();

    const generalGetList = () => {
        getData(getSlug).then(d => {
            setLoading(false)
            setData(d)
        })
    }

    const generalNew = (obj) => {
        formDataCreateData(obj).then(
            newObj => {
                if (newObj) {
                    setData([...data, newObj])
                    enqueueSnackbar('Elmentve!', {variant: 'success'})
                }
            }
        )
    }

    const generalEdit = (obj) => {
        formDataUpdateData(current, obj).then(editedObj => {
            const editedDataList = data.map(d => {
                if (current === d.id) return {...d, ...editedObj}
                return d;
            });
            setData(editedDataList)
            enqueueSnackbar('Szerkesztve!', {variant: 'success'})
        })
    }

    // const addImageToData = (obj) => {
    //     formDataUpdateData(current, obj).then(editedObj => {
    //         const editedDataList = data.map(d => {
    //             if (current === d.id) return {...d, ...editedObj}
    //             return d;
    //         });
    //         setData(editedDataList)
    //         enqueueSnackbar('Szerkesztve!', {variant: 'success'})
    //     })
    // }

    const generalDelete = () => {
        deleteData(current).then(() => {
            setData(data.filter(d => (d.id !== current)))
            enqueueSnackbar('Törölve!', {variant: 'success'})
        })
    }

    const handleNew = () => {
        const formData = getFormData(formRef.current)
        generalNew(formData)
    }

    const handleEdit = () => {
        let formData = getFormData(formRef.current)
        generalEdit(formData)
    }

    // const handleAddImageToData = (img) => {
    //     addImageToData(getFormDataWithImage(formRef.current, img))
    // }

    const getObj = (id) => {
        return data.find(service => service.id === id)
    }

    useEffect(generalGetList, [])

    const speedDialActions = [{
        icon: <AddIcon onClick={() => setNewPopupOpen(true)}/>,
        name: `Új ${title}`,
    }, ...extraSpeedDialActions];

    const editForm = React.Children.map(form, i => {
        const obj = getObj(current)
        if (React.isValidElement(form)) {
            return React.cloneElement(form, {obj});
        }
        return form;
    });


    return (<div>
        <MiniDrawer title={pluralTitle}>
            {loading ? <>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10}}
                    open={loading}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </> : <>
                <Grid container spacing={2}>
                    {data.map(d => (
                        <Grid item lg={4} key={`card_${d.id}`}>
                            <PropCard obj={d} deleteHandler={() => {
                                setCurrent(d.id)
                                setDeletePopupOpen(true)
                            }} editHandler={() => {
                                setCurrent(d.id)
                                setEditPopupOpen(true)
                            }} />
                        </Grid>
                    ))}
                </Grid>
            </>}

            <BasicSpeedDial actions={speedDialActions}/>

            <ScrollDialog
                title={`Új ${title}`}
                open={newPopupOpen}
                onClose={() => (setNewPopupOpen(false))}
                onSave={() => {
                    handleNew()
                    setNewPopupOpen(false)
                }}>
                {form}
            </ScrollDialog>

            <ScrollDialog
                title={`${title} szerkesztése`}
                open={editPopupOpen}
                onClose={() => (setEditPopupOpen(false))}
                onSave={() => {
                    handleEdit()
                    setEditPopupOpen(false)
                }}>
                {editForm}
            </ScrollDialog>

            <AlertDialog
                onNo={() => (setDeletePopupOpen(false))}
                onYes={() => {
                    generalDelete(current)
                    setDeletePopupOpen(false)
                }}
                open={deletePopupOpen}
                title={`Biztosan törli?`}
                content={`Nem vonható vissza.`}/>

            {extraAlertDialogs.map((d, i) =>
                <AlertDialog
                    key={`alert_${i}`}
                    onNo={d.onNo}
                    onYes={d.onYes}
                    open={d.open}
                    title={d.title}
                    content={d.content}/>)}
        </MiniDrawer>
    </div>)
}

export default CardView