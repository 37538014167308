import {createContext} from "react";

const ConfigContext = createContext();

export default ConfigContext;

export const ConfigProvider = ({ children }) => {

    const contextData = {
        baseUrl: 'https://apartmanback.trilogic.hu/',
        apartmentId: 1
    }

    return <ConfigContext.Provider value={contextData}>{children}</ConfigContext.Provider>
}